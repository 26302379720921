/* ************************************* MAIN : START : Utility Classes ********************************** */
.PurchaseOrderMasterView-UTL--flexContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 50px;
	width: 100%;
}

.PurchaseOrderMasterView-UTL--flexContainer > div {
	flex: 1;
}

@media only screen and (max-width: 768px) {
	.PurchaseOrderMasterView-UTL--flexContainer {
		display: block;
	}
}

/* ************************************* MAIN : END : Utility Classes ********************************** */

/* ************************************* MAIN : START : Footer Section ********************************** */

.PurchaseOrderMasterView__footer {
	border-top: 1px solid rgba(128, 128, 128, 0.438);
	margin-top: 10px;
	padding-top: 15px;
}

.PurchaseOrderMasterView__footer__errorViewer {
	margin-bottom: 10px;
}

.PurchaseOrderMasterView__footer__buttonList {
	display: flex;
	justify-content: flex-end;
}

/* ************************************* MAIN : END : Footer Section ********************************** */
