/* NOTE : Increasing each form sub sections gap. Because it look too cluttered. */
.CmvMasterTab form > div {
	margin-top: 50px;
}

.CmvMasterTab form > div:first-child {
	margin-top: 0;
}

/* ************************************* MAIN : START : Address Info Form Section ********************************** */

.CmvMasterTab__addressInfoSection____addressSetHeader {
	margin-bottom: 15px;
	margin-top: 15px;
}

.CmvMasterTab__addressInfoSection____addressSetHeader__title {
	font-size: small;
	font-style: italic;
	font-weight: 500;
	margin-right: 10px;
	text-transform: uppercase;
}

.CmvMasterTab__addressInfoSection____addressAdder {
	display: flex;
	justify-content: flex-end;
}

/* ************************************* MAIN : END : Address Info Form Section ********************************** */
