.PmvMasterTab__customInfoSection__formControlsWrapper {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 768px) {
	.PmvMasterTab__customInfoSection__formControlsWrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}
