/* STYLES */

.ProductItemList {
	height: 250px;
	overflow: auto;
}

.table-row-highlight {
	background: #d4edf8 !important;
}

.table-row-highlight td {
	background: none !important;
}
.table-row-warn {
	background: rgb(255, 242, 240) !important;
}
.table-row-warn td {
	background: none !important;
}
