/* STYLES */
.eco-app-content {
    max-height: calc(100vh - 80px);
    max-width: calc(100vw - 64px);
    overflow: auto!important;
  }
  
  .ant-table-content{
    overflow: auto;
    max-height: calc(100vh - 250px);
  }