.mr-12 {
  margin-right: 12px;
}

#image_upload_container .ant-upload-list-picture-card-container {
  display: none;
}

/* Ant Table Related Styles Start */

.ant-table-content {
  border: 1px solid #efefef;
}

.ant-table-content .ant-table-row {
  border: none !important;
}

.ant-table-tbody>tr>td {
  border-bottom: none !important;

}
.ant-table-thead > tr > th {
  background: #fafafa !important

}

.ant-table-tbody>tr>td {
  padding: 8px 16px !important
}

/* Ant Table Related Styles End */

.centered-card {
  margin-left: auto;
  margin-right: auto;
}

.centered-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.slick-slider .slick-list {
  height: 180px;
  padding: 10px;
}
