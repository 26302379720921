
.links{
	background-color: transparent;
    color: #1890ff;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color .3s;
}


.login-page {
	min-height: 100vh;
}

.login-page-container {
  height: 80vh;
  min-height: 400px;
  width: 60%;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.login-page-image-container {
  /* height: -webkit-fill-available; */
  height: 100%;
  width: 38%;
  background: url("../../assets/img/login-background-image.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-page-image-container-content img {
  width: 80%;
  float: left;
}

.login-page-image-container-content {
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.login-page-form-container {
  height: -webkit-fill-available;
  width: 62%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-form {
  width: 55%;
  height: fit-content;
  align-items: flex-start;
}

.login-page-form > * {
  width: 100%;
  text-align: left;
}

.login-page-form img {
  width: 30px;
}

.login-form-elements-mt {
  margin-top: 16px;
}
.eco-app-content{
  /* background-color: green; */
  overflow: auto!important;
  max-width: calc(100vw - 64px);
  max-height: calc(100vh - 60px);
}


@media (max-width: 768px) {
  .login-page-image-container {
    display: none;
  }
  
  .login-page-form-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .login-page-container {
    height: 100vh;
    width: 100%;
  }

  .login-page-form {
    width: 75%;
  }
}

@media (max-height: 380px) and (orientation:landscape) {
  .login-form-elements-mt {
    margin-top: 4px
  }
}

/* @media(min-width:600px){
  .eco-app-content{
    overflow: scroll!important;
    max-width: 100vw;
  }
} */
