.ProductMasterView {
}

.ProductMasterView____notesSection {
	font-size: smaller;
	margin-bottom: 20px;
}

/* ************************************* MAIN : START : Utility Classes ********************************** */
.ProductMasterView-UTL--flexContainer {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.ProductMasterView-UTL--flexContainer > div {
	flex: 1;
}

.ProductMasterView-UTL--flexContainer > div:first-child {
	margin-right: 35px;
}

@media only screen and (max-width: 768px) {
	.ProductMasterView-UTL--flexContainer {
		display: block;
	}

	.ProductMasterView-UTL--flexContainer > div:first-child {
		margin-right: 0;
	}
}

/* ************************************* MAIN : END : Utility Classes ********************************** */

/* ************************************* MAIN : START : PriceInputGrouper Item ********************************** */
.PmvMasterTab____priceInputGrouper {
	align-items: center;
	display: flex;
}

.PmvMasterTab____priceInputGrouper__input {
	margin: 0 5px;
}

.PmvMasterTab____priceInputGrouper__suffix {
	font-size: small;
}

/* ************************************* MAIN : END: PriceInputGrouper Item ********************************** */

/* ************************************* MAIN : START : AutoCalcField Item ********************************** */
.ProductMasterView____autoCalcField {
	align-items: center;
	background: #f8ffe69c;
	border: 1px dotted rgba(128, 128, 128, 0.301);
	border-radius: 5px;
}

/* AntD <Form.Item>'s Label */
.ProductMasterView____autoCalcField.ant-form-item .ant-form-item-label {
	background: #eafac1de;
	margin-right: 10px;
	padding: 5px;
}

/* AntD Disabled Normal Inputs & Numeric Inputs inside <Form.Item> */
.ProductMasterView____autoCalcField.ant-form-item .ant-input-disabled,
.ProductMasterView____autoCalcField.ant-form-item .ant-input-number-disabled {
	background: none;
	color: inherit;
}

/* ************************************* MAIN : END : AutoCalcField Item ********************************** */

/* ************************************* MAIN : START : Footer Section ********************************** */

.ProductMasterView__footer {
	border-top: 1px solid rgba(128, 128, 128, 0.438);
	margin-top: 10px;
	padding-top: 15px;
}

.ProductMasterView__footer__errorViewer {
	margin-bottom: 10px;
}

.ProductMasterView__footer__buttonList {
	display: flex;
	justify-content: flex-end;
}

/* ************************************* MAIN : END : Footer Section ********************************** */
