.RouteContentWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.RouteContentWrapper__content {
	background: white;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 30px;
}
