.CustomerOverview____headerWrapper {
	align-items: top;
	display: flex;
}

.CustomerOverview__title {
	background: #1b4571;
	border-radius: 0 10px 10px 0;
	flex: 1;
	margin-bottom: 35px;
	margin-left: -25px;
	margin-right: 20px;
	padding: 5px;
	padding-left: 25px;
}

.CustomerOverview__title h2 {
	color: white;
	margin: 0;
}

.CustomerOverview__buttons {
	display: flex;
}
