.PromvMasterTab__baseProformaInfoSection__inputsWrapper {
	display: grid;
	gap: 0 50px;
	grid-template-columns: 1fr 1fr;
}

.Promvmastertab__confirmed_status {
	border: 1px solid #ddd;
	padding: 12px;
}

.Promvmastertab__confirmed_status a {
	color: rgb(27, 4, 161);
	font-size: 16px;
}

.Promvmastertab__po_id_container {
	display: flex;
}
.Promvmastertab__po_id_title {
	width: 140px;
}
