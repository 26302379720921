.ImageViewer__generalInfo__noImages {
	color: gray;
	font-size: small;
	font-style: italic;
	margin-bottom: 25px;
}

/* ************************************************** MAIN SECTION : START : OVERRIDES : SWIPER  ***************************************************/

/* Swiper Internal - Main Wrapper Div */
.ImageViewer div.swiper-container {
	height: 100%;
	padding: 20px 45px; /* Tweak to avoid Navigation & Pagination items overlapping with slide contents. */
	padding-bottom: 50px;
}

/* Swiper Internal - Pagination Container */
.ImageViewer .swiper-pagination {
	bottom: 0;
}

/* ************************************************** MAIN SECTION : END : OVERRIDES : SWIPER  ***************************************************/

/* ************************************************** MAIN SECTION : START : SWIPER MODE SECTION ***************************************************/

.ImageViewer__swiperMode {
	height: 100%;
	position: relative;
	width: 100%;
}

.ImageViewer__swiperMode img {
	max-height: 150px;
	max-width: 100%;
	object-fit: contain;
}

/* ************************************************** MAIN SECTION : END : SWIPER MODE SECTION ***************************************************/

/* ************************************************** MAIN SECTION : START : SLIDE CONTAINER  ***************************************************/

/* This is the Container that contain individual slides */

.ImageViewer____slideContainer {
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	text-align: center;
}

/* To fix image Overflow/OverExtend Issue, So "InfoSection" get cropped sometimes. */
.ImageViewer____slideContainer,
.ImageViewer____slideContainer > div,
.ImageViewer____slideContainer____imageContainer,
.ImageViewer____slideContainer____imageContainer img {
	height: 100%;
}

.ImageViewer____slideContainer > div {
	position: relative;
}

.ImageViewer____slideContainer___infoSection {
	background: rgba(243, 229, 229, 0.733);
	border: 0.5px dashed rgba(12, 12, 12, 0.205);
	border-radius: 4px;
	bottom: 10px;
	font-size: smaller;
	left: 50%;
	padding: 3px 10px;
	position: absolute;
	text-align: center;
	transform: translateX(-50%);
}

.ImageViewer____slideContainer___infoSection div {
	white-space: nowrap;
}

/* ************************************************** MAIN SECTION : END : SLIDE CONTAINER  ***************************************************/

/* ************************************************** MAIN SECTION : START : GRID MODE SECTION  ***************************************************/

.ImageViewer__gridMode {
	align-items: stretch;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	margin-top: 15px;
}

.ImageViewer__gridMode img {
	/* box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3); */
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	height: 100%;
	max-height: 200px;
	object-fit: contain;
	padding: 10px;
	width: 100%;
}

/* ************************************************** MAIN SECTION : START : END MODE SECTION  ***************************************************/

/* ************************************************** MAIN SECTION : START : FULLSIZE MODE SECTION  ***************************************************/
.ImageViewer__fullSizeMode {
	background: rgba(252, 252, 252, 0.994);
	bottom: 0;
	height: 90vh; /* Same as Modal Height */
	left: 0;
	padding: 20px;
	position: absolute;
	right: 0;
	top: 55px;
	z-index: 2000;
}

/* Swiper Internal - Wrapper Div */
.ImageViewer__fullSizeMode > div.swiper-container {
	background: rgb(233, 223, 223);
	height: 100%;
}

/* Overriding  SliderContainer */
.ImageViewer__fullSizeMode .ImageViewer____slideContainer {
	cursor: auto;
}

.ImageViewer__fullSizeMode__closeButton {
	position: absolute !important;
	right: 20px;
	z-index: 100;
}

.ImageViewer__fullSizeMode img {
	max-height: fit-content;
	max-width: 100%;
	object-fit: contain;
}

/* ************************************************** MAIN SECTION : END : FULLSIZE MODE SECTION  ***************************************************/
