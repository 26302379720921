/* These Utility Classes will be used in Multiple relevant component's like PromvMasterTab, PromvItemsTab, Etc.. */

/* ************************************* MAIN : START: Grid Wrapper ********************************** */

.ProformaMasterView-UTL--gridWrapper {
	display: grid;
	gap: 30px;
	grid-template-columns: minmax(0, 2fr) 1fr;
}

@media only screen and (max-width: 1200px) {
	.ProformaMasterView-UTL--gridWrapper {
		grid-template-columns: minmax(0, 1.5fr) 1fr;
	}
}

@media only screen and (max-width: 992px) {
	.ProformaMasterView-UTL--gridWrapper {
		grid-template-columns: minmax(0, 1fr);
	}
}

/* ************************************* MAIN : END : Grid Wrapper ********************************** */

/* ************************************* MAIN : START: Sub Section Divider ********************************** */

/* NOTE : Increasing each form sub sections gap. Because it look too cluttered. */
.ProformaMasterView-UTL--formSubSection {
	margin-top: 50px;
}

.ProformaMasterView-UTL--formSubSection:first-child {
	margin-top: 0;
}

/* ************************************* MAIN : START: Sub Section Divider ********************************** */
