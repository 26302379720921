/* STYLES */
.eco-secondary-side-panel__container {
    overflow-y: auto;
}

.eco-secondary-side-panel__container::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  .eco-secondary-side-panel__container::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .eco-secondary-side-panel__container::-webkit-scrollbar-thumb {
    background: #ddd; 
  }
  
  /* Handle on hover */
  .eco-secondary-side-panel__container::-webkit-scrollbar-thumb:hover {
    background: #aaa; 
  }

  .eco-action-list__section {
    overflow-y: auto;
  }
  .report-widget {
    text-align: center;
  }

  /* Side menu toggle with ison */

  .eco-side-menu-toggle::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  .eco-side-menu-toggle::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .eco-side-menu-toggle::-webkit-scrollbar-thumb {
    background: #999; 
  }
  
    .ant-menu-submenu-open::-webkit-scrollbar{
    width:6px;
  }
  .eco-side-menu-toggle > .ant-menu-item > a{
    white-space: inherit!important;
  }

  .ant-menu-submenu-popup > .ant-menu::-webkit-scrollbar {
    width: 6px;
  }

  .ant-menu-submenu-popup > .ant-menu::-webkit-scrollbar-track {
    background: #f1f1f1; 
  } 

  .ant-menu-submenu-popup > .ant-menu::-webkit-scrollbar-thumb {
    background: #999; 
  }
  .ant-menu-submenu-popup{
    top: 55px !important;;
  }
  .add-items-table .ant-table-content {
    overflow: visible!important;
    
  }
