.HomePage {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 100vh;
	text-align: center;
}

.HomePage img {
	max-width: 500px;
}
