.ProductOverview____headerWrapper {
	align-items: top;
	display: flex;
}

.ProductOverview__title {
	background: #1b4571;
	border-radius: 0 10px 10px 0;
	flex: 1;
	margin-bottom: 35px;
	margin-left: -25px;
	margin-right: 20px;
	padding: 5px;
	padding-left: 25px;
}

.ProductOverview__title h2 {
	color: white;
	margin: 0;
}

/* ************************************************** MAIN SECTION : START : UTILITY CLASSES  ***************************************************/
.ProductOverview--UTL--subtitleText {
	color: gray;
	font-size: smaller;
	font-style: italic;
	padding-left: 5px;
}
.duration-select{
	width: 200px!important;
}

/* ************************************************** MAIN SECTION : END : UTILITY CLASSES  ***************************************************/
