.ProductOverviewItem {
	color: #3e4b5b;
	flex: 1;
	margin: 10px;
}

.ProductOverviewItem__header {
	color: rgba(90, 99, 126, 0.49);
	font-size: 0.8rem;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
	white-space: nowrap;
}
