.form-row {
    margin-bottom: 16px;
}

.form-lbl-wrapper {
    display: flex;
    height:100%;
    min-height: 40px;
    align-items: center;
    justify-content: space-between;
}