.mr-12 {
  margin-right: 12px;
}

#image_upload_container .ant-upload-list-picture-card-container {
  display: none;
}

/* Ant Table Related Styles Start */

.ant-table-content {
  border: 1px solid #efefef;
}

.ant-table-content .ant-table-row {
  border: none !important;
}

.ant-table-tbody>tr>td {
  border-bottom: none !important;

}
.ant-table-thead > tr > th {
  background: none !important;
}

.ant-table-tbody>tr>td {
  padding: 8px 16px !important
}

/* Ant Table Related Styles End */

/*Product Overview Styles */
.stats-card-block {
  width:48% !important
}
.bordered-section{
  border: 1px solid #C6C7D2;
  border-radius: 4px;
  padding: 20px;
}

.brand__card{
  display: flex;
  justify-content: space-evenly;
}
.brand__search #ecoTextInput {

  width:100%;
  object-fit: contain;
  border: 1px solid #cecece;
  

}
.brand__image{
  object-fit: contain;
  width:200px;
  height:5px;
}

.eco-card .eco-card--body {
  padding: 2rem 1rem !important;
}
