.PmvImagesTabImageViewer__toolbar {
	display: flex;
	justify-content: flex-end;
}

.PmvImagesTabImageViewer__toolbar > button {
	margin-left: 5px;
}

.PmvImagesTabImageViewer____thumbImgWrapper {
	position: relative;
}

.PmvImagesTabImageViewer____thumbImgWrapper__mainIndicator {
	background: #272424c7;
	border-radius: 100%;
	color: white;
	font-size: smaller;
	padding: 4px 8px;
	position: absolute;
	right: 44%;
	top: -10px;
	z-index: 1;
}
