.SupplierSearcher {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
}

/* ************************************************** MAIN SECTION : START : Customer Searcher Form Items ***************************************************/

.SupplierSearcher__supplierSearcher__searchInputGroup {
	display: flex;
}

/* ************************************************** MAIN SECTION : END : Customer Searcher Form Items ***************************************************/

/* ************************************************** MAIN SECTION : START : Intermediate Info/Loader Items and Customer List  ***************************************************/

.SupplierSearcher_____contentWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
}

/* ************************************************** MAIN SECTION : END : Intermediate Info/Loader Items and Customer List  ***************************************************/
