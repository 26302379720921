.ProductSearcher {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
}

/* ************************************************** MAIN SECTION : START : Product Searcher Form Items ***************************************************/
.ProductSearcher__productSearcher__searchGroup {
	display: flex;
}

/* ************************************************** MAIN SECTION : END : Product Searcher Form Items ***************************************************/

/* ************************************************** MAIN SECTION : START : Intermediate Info/Loader Items and Product List  ***************************************************/
.ProductSearcher_____contentWrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
}

/* ************************************************** MAIN SECTION : END : Intermediate Info/Loader Items and Product List  ***************************************************/
