.InventoryProductsPage {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

/* ************************************************** MAIN SECTION : START : Toolbar ***************************************************/
.InventoryProductsPage__toolbar {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
}

/* ************************************************** MAIN SECTION : END : Toolbar ***************************************************/
