.PmvImageTabImageUploader {
	position: relative;
}

.PmvImageTabImageUploader__formOpenerButton {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}

/* ************************************************** MAIN SECTION : START : Loader View  ***************************************************/

.PmvImageTabImageUploader__uploadingProgressView {
	background: rgb(255, 255, 255);
	bottom: 0;
	height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

/* ************************************************** MAIN SECTION : END : Loader View  ***************************************************/

/* ************************************************** MAIN SECTION : START : Form Items  ***************************************************/
.PmvImageTabImageUploader__formItemsWrapper__sectionDivider {
	display: grid;
	grid-gap: 50px;
	grid-template-columns: 1fr 1fr;
}

.PmvImageTabImageUploader__formItemsWrapper____uploadSectionHeader {
	font-size: small;
	font-style: italic;
	font-weight: 500;
	margin-bottom: 15px;
}

.PmvImageTabImageUploader____imageSelector__imagePreview {
	box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0, rgba(27, 31, 35, 0.15) 0 0 0 1px;
	height: 150px;
	margin-top: 10px;
	width: 150px;
}

.PmvImageTabImageUploader____imageSelector__imagePreview img {
	height: 100%;
	object-fit: cover;
	overflow: hidden;
	width: 100%;
}

@media only screen and (max-width: 768px) {
	.PmvImageTabImageUploader__formItemsWrapper__sectionDivider {
		grid-template-columns: 1fr;
	}
}

/* ************************************************** MAIN SECTION : END : Form Items  ***************************************************/
