.CusOveGeneralSection_____contentWrapper {
	width: 100%;
}

.CusOveGeneralSection_____contentWrapper > div {
	margin-top: 20px;
}

/* ************************************* MAIN : START : MainInfo Section ********************************** */

.CusOveGeneralSection__mainInfo_____top {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 40px;
	justify-content: center;
}

.CusOveGeneralSection__mainInfo_____bottom {
	justify-content: center;
	margin-top: 20px;
}

/* **************** SUBSECTION : START : Logo Area **************** */

.CusOveGeneralSection__mainInfo__image {
	/* NOTE : Make sure this amount of width is defined in "CusOveGeneralSection__mainInfo__statusInfo" and vice-versa.
						It is done to make sure "CusOveGeneralSection__mainInfo__creditInfo" get centered nicely.
		*/
	width: 100px;
}

.CusOveGeneralSection__mainInfo__image img {
	width: 100%;
}

/* **************** SUBSECTION : END : Logo Area **************** */

/* **************** SUBSECTION : START : General Info Area **************** */

.CusOveGeneralSection__mainInfo__genInfo {
	font-size: 1.2em;
	line-height: 1.2em;
	text-align: center;
}

.CusOveGeneralSection__mainInfo__genInfo__title {
	font-size: 1.3em;
	font-weight: 500;
	padding-bottom: 10px;
}

.CusOveGeneralSection__mainInfo__genInfo__accNum {
	font-size: 1.1em;
	font-weight: 500;
	margin-top: 10px;
}

/* **************** SUBSECTION : END : General Info Area **************** */

/* **************** SUBSECTION : START : Status Info Area **************** */

.CusOveGeneralSection__mainInfo__statusInfo {
	text-align: center;

	/* NOTE : Make sure this amount of width is defined in "CusOveGeneralSection__mainInfo__statusInfo" and vice-versa.
						It is done to make sure "CusOveGeneralSection__mainInfo__creditInfo" get centered nicely.
		*/
	width: 100px;
}

.CusOveGeneralSection__mainInfo__statusInfo__header {
	font-weight: 500;
}

.CusOveGeneralSection__mainInfo__statusInfo__icon {
	font-size: 2.5em;
}

.CusOveGeneralSection__mainInfo__statusInfo__subTitle {
	font-size: smaller;
	margin-top: -5px;
	text-transform: uppercase;
}

/* **************** SUBSECTION : END : Status Info Area **************** */

/* **************** SUBSECTION : START : Credit Info Area **************** */
.CusOveGeneralSection__mainInfo__creditInfo {
	display: flex;
	flex-wrap: wrap;
	font-size: small;
	justify-content: center;
}

.CusOveGeneralSection__mainInfo__creditInfo span {
	font-weight: 500;
}

.CusOveGeneralSection__mainInfo__creditInfo > div {
	background: rgba(231, 226, 226, 0.555);
	border-radius: 4px;
	margin: 5px 10px;
	padding: 3px 7px;
}

/* **************** SUBSECTION : END : Credit Info Area **************** */

/* ************************************* MAIN : END : MainInfo Section ********************************** */

/* ************************************* MAIN : START : Contact Actions Section ********************************** */
.CusOveGeneralSection__contactActions {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 500px;
	text-align: center;
}

.CusOveGeneralSection__contactActions__action {
	cursor: pointer;
}

.CusOveGeneralSection__contactActions__action:hover {
	transform: scale(1.05);
}

.CusOveGeneralSection__contactActions__action__icon {
	font-size: 2.5em;
}

.CusOveGeneralSection__contactActions__action__desc {
	font-size: smaller;
	margin-top: -8px;
}

/* ************************************* MAIN : END : Contact Actions Section ********************************** */

/* ************************************* MAIN : START : Address Info Section & Contact Info Section ********************************** */

.CusOveGeneralSection__addressInfo__addressTypeSelector,
.CusOveGeneralSection__contactInfo__contactTypeSelector {
	margin-bottom: 15px;
	margin-left: auto;
	max-width: 200px;
}

/* ************************************* MAIN : END : Address Info Section & Contact Info Section ********************************** */

/* ************************************* MAIN : START: Media Queries ********************************** */

@media only screen and (max-width: 480px) {
	.CusOveGeneralSection__contactActions__action__icon {
		font-size: 2em;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */
