.offer-type-card{
    min-height: 250px;
    max-width: 300px;
    padding: 20px;
    box-shadow: 0px 0px 10px 1px rgb(226 227 233);
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
}
.selected-offer-card{
    box-shadow: 0px 0px 10px 1px #9acef8!important;
}
.slick-list{
    height: auto!important;
}

.offer-qty-button{
    border: 1px solid #F7E1A1;
    display: flex;
    background-color: #FDF8E8;
    padding: 5px;
    border-radius: 4px;
    vertical-align: middle;
}

.eco-steps .ant-steps .ant-steps-item-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;    
}

.eco-steps .ant-steps .ant-steps-item-container .ant-steps-item-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.eco-steps .ant-steps .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    font-size: 14px;
    font-weight: 600;
}

.eco-steps .ant-steps .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title::after {
    height: 2px;
}

.eco-steps .ant-steps .ant-steps-item-active .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: #4D4D4D;
}

.eco-steps .ant-steps .ant-steps-item-active .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title::after {
    background-color: #039BE5;
}

.suggestions-container {
    position: fixed !important;
    z-index: 10;
}